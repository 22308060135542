/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */
export default function() {
  const scriptNodes = document.querySelectorAll('.load-external-scripts script');

  scriptNodes.forEach((node) => {
    // @ts-ignore
    // exclude ld+json objects used for seo purposes
    if (node.type !== 'application/ld+json') {
      const s = document.createElement('script');

      // @ts-ignore
      s.type = node.type || 'text/javascript';

      // @ts-ignore
      s.async = true;

      // @ts-ignore
      if (node.attributes.src) {
        // @ts-ignore
        s.src = node.attributes.src.value;
      } else {
        s.innerHTML = node.innerHTML;
      }

      document.getElementsByTagName('head')[0].appendChild(s);
    }
  });
}
