/* eslint-disable */
/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */

var trustAllScripts = require('./src/utils/trustAllScripts').default;

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location) {
  // Check for location so build does not fail
  if (location?.hash) {
    const id = location.hash.replace(/#/, '');

    const item = document.getElementById(id);

    item?.scrollIntoView();
  }

  return true;
}

function initGTMOnEvent(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
}

function initGTM() {
  if (window.gtmDidInit) {
    return false;
  }

  window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
  window.dataLayer = window.dataLayer || [];

  var script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GA_TRACKING_ID}`;

  script.onload = function() {
    dataLayer.push({
      // ensure PageViews are always tracked
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
      'gtm.uniqueEventId': 0,
    });
  };

  document.head.appendChild(script);
}

exports.onClientEntry = function() {
  // if (!document.cookie.includes(process.env.TSR_ADS_SERVICE_COOKIE_NAME)) {
  //   const services = ['adthrive', 'adsense'];
  //   const name = services[Math.round(Math.random())];
  //   document.cookie = `${process.env.TSR_ADS_SERVICE_COOKIE_NAME}=${name};path=/`;
  // }

  if (document.readyState !== 'loading') {
    // either interactive or complete/loaded
    setTimeout(initGTM, 3500);
  } else {
    document.onreadystatechange = function() {
      if (document.readyState !== 'loading') setTimeout(initGTM, 3500);
    };

    document.addEventListener('scroll', initGTMOnEvent);
    document.addEventListener('mousemove', initGTMOnEvent);
    document.addEventListener('touchstart', initGTMOnEvent);
  }
};

exports.onRouteUpdate = function({ location }) {
  trustAllScripts();

  scrollToAnchor(location);
};
